import { get, useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import {
  type QueryClient,
  type QueryFunctionContext,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  type UseInfiniteQueryResult,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { OPEN_SHIFT_LIST_PAGE_SIZE } from "../../constants";
import {
  type BookabilityStatusRequest,
  bookabilityStatusRequestSchema,
  type BookabilityStatusResponse,
  bookabilityStatusResponseSchema,
} from "../types";

export type BookabilityStatusParams = Omit<BookabilityStatusRequest, "shiftIds"> & {
  shiftIds: string[];
};

export const GET_BOOKABILITY_STATUS_PATH = "/bookability-status";
export const GET_BOOKABILITY_STATUS_URL = `${environmentConfig.REACT_APP_BASE_API_URL}${GET_BOOKABILITY_STATUS_PATH}`;

export async function invalidateBookabilityStatus(queryClient: QueryClient): Promise<void> {
  await queryClient.invalidateQueries({ queryKey: [GET_BOOKABILITY_STATUS_URL] });
}

export function useBookabilityStatus(
  params: BookabilityStatusParams,
  options: UseGetQueryOptions<BookabilityStatusResponse> = {}
): UseQueryResult<BookabilityStatusResponse> {
  const { workerId, shiftIds, returnFirstUnmetCriteria = true } = params;

  const queryParams: BookabilityStatusRequest = {
    workerId,
    shiftIds: shiftIds.join(","),
    returnFirstUnmetCriteria,
  };

  return useGetQuery({
    url: GET_BOOKABILITY_STATUS_URL,
    queryParams,
    requestSchema: bookabilityStatusRequestSchema,
    responseSchema: bookabilityStatusResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_BOOKABILITY_STATUS_FAILURE,
    },
    ...options,
  });
}

export function usePaginatedBookabilityStatus(
  params: BookabilityStatusParams,
  options: UseInfiniteQueryOptions<BookabilityStatusResponse, AxiosError> = {}
): UseInfiniteQueryResult<BookabilityStatusResponse> {
  const { workerId, shiftIds, returnFirstUnmetCriteria = true } = params;

  return useInfiniteQuery({
    queryKey: [GET_BOOKABILITY_STATUS_URL, params],
    queryFn: async ({ pageParam: pageCount = 0 }: QueryFunctionContext<QueryKey, number>) => {
      const response = await get({
        url: GET_BOOKABILITY_STATUS_URL,
        queryParams: {
          workerId,
          shiftIds: shiftIds
            .slice(
              OPEN_SHIFT_LIST_PAGE_SIZE * pageCount,
              OPEN_SHIFT_LIST_PAGE_SIZE * (pageCount + 1)
            )
            .join(","),
          returnFirstUnmetCriteria,
        },
        requestSchema: bookabilityStatusRequestSchema,
        responseSchema: bookabilityStatusResponseSchema,
      });
      return response.data;
    },
    getNextPageParam: (_lastPage, allPages) => {
      if (shiftIds.length > allPages.length * OPEN_SHIFT_LIST_PAGE_SIZE) {
        return allPages.length;
      }

      return undefined;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_PAGINATED_BOOKABILITY_STATUS_FAILURE,
    },
    ...options,
  });
}
